import firebase from "auth/Firebase";
import axios from "axios";
import React, { useContext, useState } from "react";

import { useError } from "../App";

export const getUrl = pathname => {
  return require("url").format(
    new URL(pathname, process.env.REACT_APP_BACKEND_SERVER_DOMAIN)
  );
};

// const HandleError = (error) => {
//   console.log(error);
//   // const [ code, setCode ] = useContext(ErrorCode);
//   // setCode(error.response ? error.response.status : -1);
//   const ctx = useContext(useError);
//   ctx.setCode(error.response ? error.response.status : -1);
// }

const restfulAPI = props => {
  const {
    pathname = "",
    method = "GET",
    params = {},
    data = {},
    onUploadProgress = null,
    responseType = "json",
    contentType = "multipart/form-data",
    handleResponse = res => console.log(res.data),
    handleError = error => console.log(error),
    handleFirebaseError = error => console.log(error)
  } = props;

  const user = firebase.auth().currentUser;
  const server = getUrl(pathname);
  user
    .getIdToken(false /* Without refreshing every time. */)
    .then(idToken => {
      axios({
        method: method,
        url: server,
        headers: {
          "content-type": contentType,
          Authorization: idToken
        },
        params: params,
        data: data,
        responseType: responseType,
        onUploadProgress: onUploadProgress
      })
        .then(handleResponse)
        .catch(handleError);
    })
    .catch(handleFirebaseError);
};

export default restfulAPI;
