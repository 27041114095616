export type WorkspaceType = {
  name: string;
};

export const DefaultWorkspace: WorkspaceType = {
  name: ""
};

export type UserType = {
  uid: string;
  name: string;
  email: string;
  workspace: WorkspaceType;
};

export const DefaultUser = {
  uid: "",
  name: "",
  email: "",
  workspace: DefaultWorkspace
};

export type PhotoType = {
  uid: string;
  name: string;
  timestamp: string;
  num_annotations: number;
  tag: string;
  state: string;
  tilesourceStatus: string;
};

export const DefaultPhoto: PhotoType = {
  uid: "",
  name: "",
  timestamp: "",
  num_annotations: 0,
  tag: "",
  state: "",
  tilesourceStatus: ""
};

export type PigeonholeType = {
  uid: string;
  name: string;
  description: string;
};

export const DefaultPigeonhole: PigeonholeType = {
  uid: "",
  name: "",
  description: ""
};

export type AnnotationType = {
  uid: string;
  photo: PhotoType;
  pigeonhole: PigeonholeType;
  locked: boolean;
  timestamp: string;
  state: string;
};

export const DefaultAnnotation: AnnotationType = {
  uid: "",
  photo: DefaultPhoto,
  pigeonhole: DefaultPigeonhole,
  locked: false,
  timestamp: "",
  state: ""
};

export type TestDataType = {
  uid: string;
  name: string;
  tag: string;
  timestamp: string;
};

export const DefaultTestData = {
  uid: "",
  name: "",
  tag: "",
  timestamp: ""
};

export type ModuleType = {
  uid: string;
  name: string;
  application: string;
  algorithm_name: string;
  parentage: string;
  uploadTaskStatus: string;
};

export const DefaultModule: ModuleType = {
  uid: "",
  name: "",
  application: "",
  algorithm_name: "",
  parentage: "",
  uploadTaskStatus: ""
};

export type TestStatType = {
  fn: Array<number>;
  fp: Array<number>;
  tn: Array<number>;
  tp: Array<number>;
  threshold: Array<number>;
};

export const DefaultTestStat = {
  fn: [],
  fp: [],
  tn: [],
  tp: [],
  threshold: []
};

export type TestType = {
  uid: string;
  name: string;
  timestamp: string;
  threshold: number;
  tag: string;
  stat: TestStatType;
  testdata: TestDataType;
  module: ModuleType;
  locked: string;
  dataDuplication: string;
  progress: any;
  status: string;
};
export const DefaultTest = {
  uid: "",
  name: "",
  timestamp: "",
  threshold: -1,
  tag: "",
  stat: DefaultTestStat,
  testdata: DefaultTestData,
  module: DefaultModule,
  locked: "",
  dataDuplication: "",
  progress: null,
  status: ""
};

export type PredictedPhotoType = {
  uid: string;
  module: ModuleType;
  photo: PhotoType;
  timestamp: string;
  status: string;
};

export const DefaultPredictedPhoto = {
  uid: "",
  module: DefaultModule,
  photo: DefaultPhoto,
  timestamp: "",
  status: ""
};

export type TestContentType = {
  uid: string;
  annotation: AnnotationType;
  predicted: PredictedPhotoType;
  test: TestType;
};

export const DefaultTestContent = {
  uid: "",
  annotation: DefaultAnnotation,
  predicted: DefaultPredictedPhoto,
  test: DefaultTest
};
