import React, { useState, useContext, createContext } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { GlobalStyleProps, RouteInfoType } from "./../types/types";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Settings from "../components/Settings";
import { ErrorSnackbar } from "components/mini/Snackbars";

import { spacing } from "@material-ui/system";
import {
  Hidden,
  CssBaseline,
  Paper as MuiPaper,
  withWidth
} from "@material-ui/core";

import { isWidthUp } from "@material-ui/core/withWidth";

const drawerWidth = 260;

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${props => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${props => props.theme.body.background};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

type DashboardPropsType = {
  routes: Array<RouteInfoType>;
  width: "md" | "xs" | "sm" | "lg" | "xl";
};

type Context = {
  code: number;
  //time: Date;
  setCode: (code: number) => void;
  //setTime: (time: Date ) => void;
};

const defaultContext: Context = {
  code: 0,
  //time: new Date(),
  setCode: () => {}
  //setTime: () => {},
};

export const ErrorContext = createContext<Context>(defaultContext);

const useCode = () => {
  const [code, setCode] = useState(0);
  //const [ time, setTime ] = useState(new Date());
  return { code, setCode };
};

const Dashboard: React.FC<DashboardPropsType> = ({
  children,
  routes,
  width
}) => {
  const ctx = useCode();

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Root>
      <ErrorContext.Provider value={ctx}>
        <CssBaseline />
        <GlobalStyle />
        <Drawer>
          <Hidden mdUp implementation="js">
            <Sidebar
              routes={routes}
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
            />
          </Hidden>
          <Hidden smDown implementation="css">
            <Sidebar
              routes={routes}
              PaperProps={{ style: { width: drawerWidth } }}
            />
          </Hidden>
        </Drawer>
        <AppContent>
          {/* <Header onDrawerToggle={handleDrawerToggle} /> */}
          <MainContent p={isWidthUp("lg", width) ? 10 : 5}>
            {children}
          </MainContent>
          {/* <Footer /> */}
          <ErrorSnackbar code={ctx.code} />
        </AppContent>
        {/* <Settings /> */}
      </ErrorContext.Provider>
    </Root>
  );
};

export default withWidth()(Dashboard);
