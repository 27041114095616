import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  RouteComponentProps
} from "react-router-dom";
import {
  dashboard as dashboardRoutes,
  auth as authRoutes,
  testAPI as testAPIRoutes
} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";

import Auth from "../auth/Auth";
import SignIn from "../pages/views/auth/SignIn";
import ResetPasswordBeforeSignIn from "../pages/views/auth/ResetPasswordBeforeSignIn";
import Page404 from "../pages/views/auth/Page404";
import { RouteInfoType } from "../types/types";

const childRoutes = (Layout: React.ElementType, routes: Array<RouteInfoType>) =>
  routes.map(({ component: Component, children, path }, index: number) => {
    return children ? (
      children.map((element, index: number) => (
        <Route
          key={index}
          path={element.path}
          exact
          render={(props: RouteComponentProps) => (
            <Layout>
              <element.component {...props} />
            </Layout>
          )}
        />
      ))
    ) : Component ? (
      <Route
        key={index}
        path={path}
        exact
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    ) : null;
  });

const Routes = () => (
  <Router>
    <Switch>
      <Route
        exact
        path="/auth/signin"
        component={(props: RouteComponentProps) => (
          <AuthLayout>
            <SignIn {...props} />
          </AuthLayout>
        )}
      />
      <Route
        exact
        path="/auth/reset-password-before-sign-in"
        component={(props: RouteComponentProps) => (
          <AuthLayout>
            <ResetPasswordBeforeSignIn {...props} />
          </AuthLayout>
        )}
      />
      <Auth>
        <Switch>
          {childRoutes(DashboardLayout, testAPIRoutes)}
          {childRoutes(DashboardLayout, dashboardRoutes)}
          {childRoutes(AuthLayout, authRoutes)}
          <Route
            render={() => (
              <AuthLayout>
                <Page404 />
              </AuthLayout>
            )}
          />
        </Switch>
      </Auth>
    </Switch>
  </Router>
);

export default Routes;
