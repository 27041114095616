import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { MuiButtonSpacingType } from "types/types";
import { Helmet } from "react-helmet";
import LanguageMenu from "components/LanguageMenu";

import firebase from "auth/Firebase";

import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  Button as MuiButton,
  Paper,
  Typography
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { RouteComponentProps } from "react-router-dom";

import { PasswordInput } from "./components/Password";

const Button = styled(MuiButton)(spacing);
const LinkButton = styled(MuiButton)<MuiButtonSpacingType>(spacing);

const Wrapper = styled(Paper)`
  padding: ${props => props.theme.spacing(6)}px;

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
`;

const SignIn = (props: RouteComponentProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const handleOnSubmit = (values: any) => {
    //spinner表示開始
    if (isMounted) setLoading(true);
    //サインイン（ログイン）処理
    firebase
      .auth()
      .signInWithEmailAndPassword(values.email, values.password)
      .then(res => {
        //正常終了時
        props.history.push("/");
        if (isMounted) setLoading(false);
      })
      .catch((error: any) => {
        //異常終了時
        if (isMounted) setLoading(false);
        alert(error);
      });
  };

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  });

  return (
    <Wrapper>
      <Helmet title={t("auth.signIn.helmetTitle")} />
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        {t("auth.signIn.title")}
      </Typography>

      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={values => handleOnSubmit(values)}
        validationSchema={Yup.object().shape({
          email: Yup.string().email().required("must be email"),
          password: Yup.string().required()
        })}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched
        }) => (
          <form onSubmit={handleSubmit}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input
                type="email"
                name="email"
                id="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <PasswordInput
                name="password"
                id="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              mb={2}
              disabled={loading}
            >
              {t("auth.signIn.signIn")}
            </Button>
            <LinkButton
              component={Link}
              to="/auth/reset-password-before-sign-in"
              fullWidth
              color="primary"
            >
              {t("auth.signIn.resetPassword")}
            </LinkButton>
          </form>
        )}
      </Formik>
      <Grid container justify="flex-end">
        <Grid item>
          <LanguageMenu />
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default SignIn;
