import React from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import firebase from "./Firebase";
import LoadingOverlay from "react-loading-overlay";

const AuthorizationProcessing = () => {
  const { t } = useTranslation();
  return (
    <LoadingOverlay
      active={true}
      spinner
      text={t("other.authorizationProcessing")}
      styles={{
        overlay: base => ({
          ...base,
          background: "#F7F9FC"
        }),
        content: base => ({
          ...base,
          color: "#996600"
        }),
        spinner: base => ({
          ...base,
          width: "100px",
          "& svg circle": {
            stroke: "rgba(255, 0, 0, 0.5)"
          }
        })
      }}
    >
      <div style={{ height: "100vh", width: "100vw" }}></div>
    </LoadingOverlay>
  );
};

class Auth extends React.Component {
  state = {
    signinCheck: false, //ログインチェックが完了してるか
    signedIn: false //ログインしてるか
  };

  _isMounted = false; //unmountを判断（エラー防止用）

  componentDidMount = () => {
    //mountされてる
    this._isMounted = true;

    //ログインしてるかどうかチェック
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        //してる
        if (this._isMounted) {
          this.setState({
            signinCheck: true,
            signedIn: true
          });
        }
      } else {
        //してない
        if (this._isMounted) {
          this.setState({
            signinCheck: true,
            signedIn: false
          });
        }
      }
    });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    //チェックが終わってないなら（ローディング表示）
    if (!this.state.signinCheck) {
      return <AuthorizationProcessing />;
    }

    //チェックが終わりかつ
    if (this.state.signedIn) {
      //サインインしてるとき（そのまま表示）
      return this.props.children;
    } else {
      //してないとき（ログイン画面にリダイレクト）
      return <Redirect to="/auth/signin" />;
    }
  }
}

export default Auth;
