import React, { useState } from "react";

import { Input, InputAdornment, IconButton, Tooltip } from "@material-ui/core";

import {
  InfoOutlined as Info,
  Visibility,
  VisibilityOff
} from "@material-ui/icons";

import { useTranslation } from "react-i18next";

interface PasswordAlertPropsType {
  capslock: boolean;
  value: string;
}

export const PasswordAlert = (props: PasswordAlertPropsType) => {
  const { t } = useTranslation();
  const { capslock, value } = props;
  return (
    <>
      {(capslock || value.match(/ /)) && (
        <InputAdornment position="start">
          <Tooltip
            title={
              <>
                {capslock && t("auth.causions.capsLock")}
                &nbsp;
                {value.match(/ /) && t("auth.causions.whiteSpace")}
              </>
            }
          >
            <Info />
          </Tooltip>
        </InputAdornment>
      )}
    </>
  );
};

interface TogglePasswordVisiablityPropsType {
  showPassword: boolean;
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
}
export const TogglePasswordVisiablity = (
  props: TogglePasswordVisiablityPropsType
) => {
  const { showPassword, setShowPassword } = props;
  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => setShowPassword(!showPassword)}
        onMouseDown={(event: any) => {
          event.preventDefault();
        }}
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );
};

interface PasswordInputPropsType {
  id?: string;
  name?: string;
  value: string;
  onChange: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onBlur: (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
}
export const PasswordInput = (props: PasswordInputPropsType) => {
  const { id = "password", name = "password", value, onChange, onBlur } = props;

  const [capslock, setCapslock] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleKeyUp = (event: any) => {
    setCapslock(event.getModifierState("CapsLock"));
  };

  return (
    <Input
      name={name}
      type={showPassword ? "text" : "password"}
      id={id}
      autoComplete="current-password"
      value={value}
      onChange={onChange}
      onKeyUp={handleKeyUp}
      onBlur={onBlur}
      startAdornment={<PasswordAlert capslock={capslock} value={value} />}
      endAdornment={
        <TogglePasswordVisiablity
          showPassword={showPassword}
          setShowPassword={setShowPassword}
        />
      }
    />
  );
};
