import React, { createContext, useEffect, useState } from "react";
import { connect } from "react-redux";

import { Helmet } from "react-helmet";

import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
import { CookiesProvider } from "react-cookie";
import { useCookies } from "react-cookie";

import maTheme from "./theme";
import Routes from "./routes/Routes";
import { AppStateType } from "./redux/reducers";
import { ThemeInitialStateType } from "./redux/reducers/themeReducers";

import { initReactI18next, I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_en from "i18n/en/common.json";
import common_jp from "i18n/jp/common.json";

i18next.init({
  react: {
    useSuspense: false
  }
});
const SetLaunguage = () => {
  const [cookies, setCookie] = useCookies(["lang"]);
  useEffect(() => {
    i18next.use(initReactI18next).init({
      debug: process.env.NODE_ENV !== "production",
      whitelist: ["jp", "en"],
      nonExplicitWhitelist: true,
      fallbackLng: "en",
      lng: cookies.lang || "en",
      resources: {
        jp: {
          translation: common_jp
        },
        en: {
          translation: common_en
        }
      },
      react: {
        wait: true
      },
      interpolation: { escapeValue: false }
    });
  }, []);
  return (
    <I18nextProvider i18n={i18next}>
      <Routes />
    </I18nextProvider>
  );
};

function App({ theme }: { theme: ThemeInitialStateType }) {
  return (
    <React.Fragment>
      <Helmet titleTemplate="%s | HORUS AI" defaultTitle="HORUS AI" />
      <CookiesProvider>
        <StylesProvider injectFirst>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
              <ThemeProvider theme={maTheme[theme.currentTheme]}>
                <SetLaunguage />
              </ThemeProvider>
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </CookiesProvider>
    </React.Fragment>
  );
}

export default connect((store: AppStateType) => ({
  theme: store.themeReducer
}))(App);
