import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { MuiButtonSpacingType } from "../../../types/types";
import { Helmet } from "react-helmet";
import firebase from "auth/Firebase";
import LanguageMenu from "components/LanguageMenu";

import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  Button as MuiButton,
  Paper,
  Typography
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { RouteComponentProps } from "react-router-dom";

const Button = styled(MuiButton)(spacing);
const LinkButton = styled(MuiButton)<MuiButtonSpacingType>(spacing);

const Wrapper = styled(Paper)`
  padding: ${props => props.theme.spacing(6)}px;
  width: 100%;

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
`;

const ResetPassword = (props: RouteComponentProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleOnSubmit = (values: any) => {
    //spinner表示開始
    setLoading(true);

    firebase
      .auth()
      .sendPasswordResetEmail(values.email)
      .then(res => {
        //正常終了時
        setLoading(false);
        setSuccess(true);
      })
      .catch((error: any) => {
        //異常終了時
        setLoading(false);
        setSuccess(false);
        alert(error);
      });
  };

  return (
    <Wrapper>
      <Helmet title={t("auth.resetPasswordBeforeSignIn.helmetTitle")} />
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        {t("auth.resetPasswordBeforeSignIn.title")}
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        {t("auth.resetPasswordBeforeSignIn.subTitle")}
      </Typography>

      <Formik
        initialValues={{ email: "" }}
        onSubmit={values => handleOnSubmit(values)}
        validationSchema={Yup.object().shape({
          email: Yup.string().email().required()
        })}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched
        }) => (
          <form onSubmit={handleSubmit}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input
                type="email"
                name="email"
                id="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              mt={2}
              disabled={loading}
            >
              {!success
                ? t("auth.resetPasswordBeforeSignIn.getEmail")
                : t("auth.resetPasswordBeforeSignIn.getEmailSuccess")}
            </Button>
            <LinkButton
              component={Link}
              to="/auth/sign-in"
              fullWidth
              color="primary"
            >
              {t("auth.resetPasswordBeforeSignIn.return")}
            </LinkButton>
          </form>
        )}
      </Formik>
      <Grid container justify="flex-end">
        <Grid item>
          <LanguageMenu />
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default ResetPassword;
