import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

import { IconButton, Button as MuiButton, Snackbar } from "@material-ui/core";

import { Alert } from "@material-ui/lab";

import { Close as CloseIcon } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { ErrorContext } from "../../layouts/Dashboard";
import { useTranslation } from "react-i18next";

const Button = styled(MuiButton)(spacing);

type SnackbarCloseReason = "timeout" | "clickaway";

type ErrorSnackbarPropsType = {
  code?: number;
};

//class SimpleSnackbar extends React.Component {
export const ErrorSnackbar = (props: ErrorSnackbarPropsType) => {
  var { code = 0 } = props;
  const [open, setOpen] = useState(false);
  const ctx = useContext(ErrorContext);
  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    ctx.setCode(0);
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (code === 0) {
      handleClose();
    } else {
      handleOpen();
    }
  }, [code]);

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={<span id="message-id">Note archived</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
      >
        <>
          {code >= 200 && code <= 299 ? (
            <Alert onClose={handleClose} severity="success">
              {code}: {t("components.mini.snackbar.200")}
            </Alert>
          ) : code >= 400 && code <= 599 ? (
            <Alert onClose={handleClose} severity="error">
              {code}:
              {code >= 500 && code <= 599
                ? t("components.mini.snackbar.500")
                : code === 400
                ? t("components.mini.snackbar.400")
                : code === 401
                ? t("components.mini.snackbar.401")
                : code === 403
                ? t("components.mini.snackbar.403")
                : code === 404
                ? t("components.mini.snackbar.404")
                : code === 405
                ? t("components.mini.snackbar.405")
                : code === 409
                ? t("components.mini.snackbar.409")
                : code === 423
                ? t("components.mini.snackbar.423")
                : t("components.mini.snackbar.unknown")}
            </Alert>
          ) : code === -1 ? (
            <Alert onClose={handleClose} severity="error">
              {code}: {t("components.mini.snackbar.-1")}
            </Alert>
          ) : (
            <Alert onClose={handleClose} severity="warning">
              {code}: {t("components.mini.snackbar.unknownCode")}
            </Alert>
          )}
        </>
      </Snackbar>
    </>
  );
};
