import React from "react";
import { useTranslation } from "react-i18next";
import async from "../components/Async";

import {
  BookOpen,
  Briefcase,
  Calendar as CalendarIcon,
  CheckSquare,
  CreditCard,
  Edit as EditIcon,
  Folder as FolderIcon,
  Grid,
  Heart,
  Image,
  Layout,
  List,
  Map,
  Monitor,
  ShoppingCart,
  PieChart,
  Settings as SettingIcon,
  Cpu as CpuIcon,
  Sliders,
  User,
  Users,
  Package as PackageIcon
} from "react-feather";

import { PanTool, Tune as TuneIcon } from "@material-ui/icons";

// Auth components
const SignIn = async(() => import("../pages/views/auth/SignIn"));
const SignUp = async(() => import("../pages/views/auth/SignUp"));
const SignOut = async(() => import("../pages/views/auth/SignOut"));
const ResetPasswordBeforeSignIn = async(
  () => import("../pages/views/auth/ResetPasswordBeforeSignIn")
);
const ResetPasswordAfterSignIn = async(
  () => import("../pages/views/auth/ResetPasswordAfterSignIn")
);
const Page404 = async(() => import("../pages/views/auth/Page404"));
const Page500 = async(() => import("../pages/views/auth/Page500"));

// Components components
const Alerts = async(() => import("../pages/components/Alerts"));
const Avatars = async(() => import("../pages/components/Avatars"));
const Badges = async(() => import("../pages/components/Badges"));
const Buttons = async(() => import("../pages/components/Buttons"));
const Cards = async(() => import("../pages/components/Cards"));
const Chips = async(() => import("../pages/components/Chips"));
const Dialogs = async(() => import("../pages/components/Dialogs"));
const ExpPanels = async(() => import("../pages/components/ExpansionPanels"));
const Lists = async(() => import("../pages/components/Lists"));
const Menus = async(() => import("../pages/components/Menus"));
const Pagination = async(() => import("../pages/components/Pagination"));
const Progress = async(() => import("../pages/components/Progress"));
const Snackbars = async(() => import("../pages/components/Snackbars"));
const Tooltips = async(() => import("../pages/components/Tooltips"));

// Dashboards components
const Default = async(() => import("../pages/dashboards/Default"));
const Analytics = async(() => import("../pages/dashboards/Analytics"));

// Forms components
const Pickers = async(() => import("../pages/forms/Pickers"));
const SelectionCtrls = async(() => import("../pages/forms/SelectionControls"));
const Selects = async(() => import("../pages/forms/Selects"));
const TextFields = async(() => import("../pages/forms/TextFields"));
const Dropzone = async(() => import("../pages/forms/Dropzone"));
const Editors = async(() => import("../pages/forms/Editors"));

// Icons components
const MaterialIcons = async(() => import("../pages/icons/MaterialIcons"));
const FeatherIcons = async(() => import("../pages/icons/FeatherIcons"));

// Pages components
const Blank = async(() => import("../pages/pages/Blank"));
const InvoiceDetails = async(() => import("../pages/pages/InvoiceDetails"));
const InvoiceList = async(() => import("../pages/pages/InvoiceList"));
const Orders = async(() => import("../pages/pages/Orders"));
const Pricing = async(() => import("../pages/pages/Pricing"));
const Profile = async(() => import("../pages/pages/Profile"));
const Settings = async(() => import("../pages/pages/Settings"));
const Tasks = async(() => import("../pages/pages/Tasks"));
const Projects = async(() => import("../pages/pages/Projects"));
const Calendar = async(() => import("../pages/pages/Calendar"));

// Tables components
const SimpleTable = async(() => import("../pages/tables/SimpleTable"));
const AdvancedTable = async(() => import("../pages/tables/AdvancedTable"));

// Chart components
const Chartjs = async(() => import("../pages/charts/Chartjs"));

// Maps components
const GoogleMaps = async(() => import("../pages/maps/GoogleMaps"));
const VectorMaps = async(() => import("../pages/maps/VectorMaps"));

// Documentation
const Docs = async(() => import("../pages/docs/Documentation"));
const Changelog = async(() => import("../pages/docs/Changelog"));
const Presentation = async(() => import("../pages/docs/Presentation"));

// API test
const GetTest = async(() => import("../pages/views/testAPI/getTest"));
// Horus Views Components
const Photos = async(() => import("../pages/views/Photos"));
const Annotate = async(() => import("../pages/views/Annotate"));
const AnnotationSets = async(() => import("../pages/views/AnnotationSets"));
const AnnotationEdit = async(() => import("../pages/views/AnnotationSetEdit"));
const AnnotationChange = async(() => import("../pages/views/AnnotationChange"));
const TrainingDatas = async(() => import("../pages/views/TrainingDatas"));
const TrainingDataEdit = async(() => import("../pages/views/TrainingDataEdit"));
const TestDatas = async(() => import("../pages/views/TestDatas"));
const TestDataEdit = async(() => import("../pages/views/TestDataEdit"));
const Module = async(() => import("../pages/views/Module"));
const Train = async(() => import("../pages/views/Train"));
const AddTrain = async(() => import("../pages/views/AddTrain"));
const Pigeonholes = async(() => import("../pages/views/Pigeonholes"));
const Test = async(() => import("../pages/views/TestPage"));
const AddTest = async(() => import("../pages/views/AddTest"));
const TestResult = async(() => import("../pages/views/TestResult"));
const OperationKey = async(() => import("../pages/views/KeyPage"));
const OperationWork = async(() => import("../pages/views/WorkPage"));
const OperationTask = async(() => import("../pages/views/TaskPage"));
const AddWork = async(() => import("../pages/views/AddWork"));
const EditWork = async(() => import("../pages/views/EditWork"));
const TopPage = async(() => import("../pages/views/TopPage"));
const OperationDashboard = async(() => import("../pages/views/Dashboard"));

const photosRoutes = {
  id: "sidebar.photo",
  path: "/photo",
  icon: <Image />,
  component: Photos,
  children: null
};

const pigeonholeRoutes = {
  id: "sidebar.pigeonhole",
  path: "/pigeonhole",
  icon: <Grid />,
  component: Pigeonholes,
  children: null
};

const annotateRoutes = {
  id: "sidebar.annotate",
  path: "/annotate",
  icon: <EditIcon />,
  component: Annotate,
  children: null
};

export const annotationChangeRoutes = {
  id: "AnnotationChange",
  path: "/annotate/:uid",
  component: AnnotationChange,
  children: null
};

const bundleRoutes = {
  id: "sidebar.bundle",
  path: "/bundle",
  icon: <FolderIcon />,
  children: [
    {
      path: "/bundle/annotation_sets",
      name: "sidebar.annotationSet",
      component: AnnotationSets
    },
    {
      path: "/bundle/training_datas",
      name: "sidebar.trainingData",
      component: TrainingDatas
    },
    {
      path: "/bundle/test_datas",
      name: "sidebar.testData",
      component: TestDatas
    }
  ],
  component: null
};

const moduleRoutes = {
  id: "sidebar.module",
  path: "/module",
  icon: <PackageIcon />,
  component: Module,
  children: null
};

export const trainRoutes = {
  id: "sidebar.train",
  path: "/train",
  icon: <CpuIcon />,
  component: Train,
  children: null
};

export const addTrainRoutes = {
  id: "AddTrain",
  path: "/addtrain",
  component: AddTrain,
  children: null
};

export const editAnnotationSetRoutes = {
  id: "AnnotationSetEdit",
  path: "/bundle/annotationset/:uid",
  component: AnnotationEdit,
  children: null
};

const editTrainingDataRoutes = {
  id: "TrainingDataEdit",
  path: "/bundle/training_data/:uid/:category",
  component: TrainingDataEdit,
  children: null
};

const editTestDataRoutes = {
  id: "TestDataEdit",
  path: "/bundle/test_data/:uid",
  component: TestDataEdit,
  children: null
};

export const addTestRoutes = {
  id: "AddTest",
  path: "/addtest",
  component: AddTest,
  children: null
};

export const testResultRoutes = {
  id: "TestResult",
  path: "/testresult/:uid",
  icon: <EditIcon />,
  component: TestResult,
  children: null
};

export const testRoutes = {
  id: "sidebar.test",
  path: "/test",
  icon: <TuneIcon />,
  component: Test,
  children: null
};

export const operationRoutes = {
  id: "sidebar.operation",
  path: "/operation",
  icon: <PanTool />,
  component: null,
  children: [
    {
      path: "/operation/dashboard",
      name: "sidebar.dashboard",
      component: OperationDashboard
    },
    {
      path: "/operation/key",
      name: "sidebar.key",
      component: OperationKey
    },
    {
      path: "/operation/work",
      name: "sidebar.work",
      component: OperationWork
    }
    /*{
      path: "/operation/task",
      name: "タスク",
      component: OperationTask
    }*/
  ]
};

export const editWorkRoutes = {
  id: "WorkEdit",
  path: "/operation/work/:uid",
  component: EditWork,
  children: null
};

export const addWorkRoutes = {
  id: "AddWork",
  path: "/operation/work/addwork",
  component: AddWork,
  children: null
};
const settingRoutes = {
  id: "設定",
  path: "setting",
  icon: <SettingIcon />,
  children: [
    {
      path: "/settings/pigeonholes",
      name: "クラス",
      component: Pigeonholes
    }
  ],
  component: null
};

const dashboardsRoutes = {
  id: "Dashboard",
  path: "/dashboard",
  header: "Pages",
  icon: <Sliders />,
  containsHome: true,
  children: [
    {
      path: "/dashboard/default",
      name: "Default",
      component: Default
    },
    {
      path: "/dashboard/analytics",
      name: "Analytics",
      component: Analytics
    }
  ],
  component: null
};

const pagesRoutes = {
  id: "Pages",
  path: "/pages",
  icon: <Layout />,
  children: [
    {
      path: "/pages/settings",
      name: "Settings",
      component: Settings
    },
    {
      path: "/pages/pricing",
      name: "Pricing",
      component: Pricing
    },
    {
      path: "/pages/blank",
      name: "Blank Page",
      component: Blank
    }
  ],
  component: null
};

const profileRoutes = {
  id: "Profile",
  path: "/profile",
  icon: <User />,
  component: Profile,
  children: null
};

const projectsRoutes = {
  id: "Projects",
  path: "/projects",
  icon: <Briefcase />,
  badge: "8",
  component: Projects,
  children: null
};

const invoiceRoutes = {
  id: "Invoices",
  path: "/invoices",
  icon: <CreditCard />,
  children: [
    {
      path: "/invoices",
      name: "List",
      component: InvoiceList
    },
    {
      path: "/invoices/detail",
      name: "Details",
      component: InvoiceDetails
    }
  ],
  component: null
};

const orderRoutes = {
  id: "Orders",
  path: "/orders",
  icon: <ShoppingCart />,
  component: Orders,
  children: null
};

const tasksRoutes = {
  id: "Tasks",
  path: "/tasks",
  icon: <CheckSquare />,
  badge: "17",
  component: Tasks,
  children: null
};

const calendarRoutes = {
  id: "Calendar",
  path: "/calendar",
  icon: <CalendarIcon />,
  component: Calendar,
  children: null
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn
    },
    // {
    //   path: "/auth/sign-up",
    //   name: "Sign Up",
    //   component: SignUp
    // },
    {
      path: "/auth/reset-password-before-sign-in",
      name: "Reset Password",
      component: ResetPasswordBeforeSignIn
    },
    {
      path: "/auth/reset-password-after-sign-in",
      name: "Reset Password",
      component: ResetPasswordAfterSignIn
    },
    {
      path: "/auth/sign-out",
      name: "Sign Out",
      component: SignOut
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500
    }
  ],
  component: null
};

export const userSettingRoutes = {
  id: "ユーザ",
  path: "/user",
  icon: <User />,
  children: [
    {
      path: "/auth/reset-password-after-sign-in",
      name: "sidebar.changePassword",
      component: ResetPasswordAfterSignIn
    },
    {
      path: "/auth/sign-out",
      name: "sidebar.signOut",
      component: SignOut
    }
  ],
  component: null
};

const componentsRoutes = {
  id: "Components",
  path: "/components",
  header: "Elements",
  icon: <Grid />,
  children: [
    {
      path: "/components/alerts",
      name: "Alerts",
      component: Alerts
    },
    {
      path: "/components/avatars",
      name: "Avatars",
      component: Avatars
    },
    {
      path: "/components/badges",
      name: "Badges",
      component: Badges
    },
    {
      path: "/components/buttons",
      name: "Buttons",
      component: Buttons
    },
    {
      path: "/components/cards",
      name: "Cards",
      component: Cards
    },
    {
      path: "/components/chips",
      name: "Chips",
      component: Chips
    },
    {
      path: "/components/dialogs",
      name: "Dialogs",
      component: Dialogs
    },
    {
      path: "/components/expansion-panels",
      name: "Expansion Panels",
      component: ExpPanels
    },
    {
      path: "/components/lists",
      name: "Lists",
      component: Lists
    },
    {
      path: "/components/menus",
      name: "Menus",
      component: Menus
    },
    {
      path: "/components/pagination",
      name: "Pagination",
      component: Pagination
    },
    {
      path: "/components/progress",
      name: "Progress",
      component: Progress
    },
    {
      path: "/components/snackbars",
      name: "Snackbars",
      component: Snackbars
    },
    {
      path: "/components/tooltips",
      name: "Tooltips",
      component: Tooltips
    }
  ],
  component: null
};

const formsRoutes = {
  id: "Forms",
  path: "/forms",
  icon: <CheckSquare />,
  children: [
    {
      path: "/forms/pickers",
      name: "Pickers",
      component: Pickers
    },
    {
      path: "/forms/selection-controls",
      name: "Selection Controls",
      component: SelectionCtrls
    },
    {
      path: "/forms/selects",
      name: "Selects",
      component: Selects
    },
    {
      path: "/forms/text-fields",
      name: "Text Fields",
      component: TextFields
    },
    {
      path: "/forms/dropzone",
      name: "Dropzone",
      component: Dropzone
    },
    {
      path: "/forms/editors",
      name: "Editors",
      component: Editors
    }
  ],
  component: null
};

const tablesRoutes = {
  id: "Tables",
  path: "/tables",
  icon: <List />,
  children: [
    {
      path: "/tables/simple-table",
      name: "Simple Table",
      component: SimpleTable
    },
    {
      path: "/tables/advanced-table",
      name: "Advanced Table",
      component: AdvancedTable
    }
  ],
  component: null
};

const iconsRoutes = {
  id: "Icons",
  path: "/icons",
  icon: <Heart />,
  children: [
    {
      path: "/icons/material-icons",
      name: "Material Icons",
      component: MaterialIcons
    },
    {
      path: "/icons/feather-icons",
      name: "Feather Icons",
      component: FeatherIcons
    }
  ],
  component: null
};

const chartRoutes = {
  id: "Charts",
  path: "/charts",
  icon: <PieChart />,
  component: Chartjs,
  children: null
};

const mapsRoutes = {
  id: "Maps",
  path: "/maps",
  icon: <Map />,
  children: [
    {
      path: "/maps/google-maps",
      name: "Google Maps",
      component: GoogleMaps
    },
    {
      path: "/maps/vector-maps",
      name: "Vector Maps",
      component: VectorMaps
    }
  ],
  component: null
};

const presentationRoutes = {
  id: "Presentation",
  path: "/",
  header: "Docs",
  icon: <Monitor />,
  component: Presentation,
  children: null
};

const documentationRoutes = {
  id: "Getting Started",
  path: "/documentation",
  icon: <BookOpen />,
  component: Docs,
  children: null
};

const changelogRoutes = {
  id: "Changelog",
  path: "/changelog",
  badge: "v1.1.0",
  icon: <List />,
  component: Changelog,
  children: null
};

// This route is not visisble in the sidebar
const privateRoutes = {
  id: "Private",
  path: "/private",
  component: Blank,
  children: null
};

const testAPIRoutes = {
  id: "TestAPI",
  path: "/testapi",
  icon: <Layout />,
  children: [
    {
      path: "/testapi/get",
      name: "Test Get",
      component: GetTest
    }
  ],
  component: null
};

const topPageRoutes = {
  id: "Presentation",
  path: "/",
  header: "Docs",
  icon: <Monitor />,
  component: TopPage,
  children: null
};

export const testAPI = [testAPIRoutes];

export const dashboard = [
  annotationChangeRoutes,
  editAnnotationSetRoutes,
  editTrainingDataRoutes,
  editTestDataRoutes,
  addTrainRoutes,
  addTestRoutes,
  photosRoutes,
  pigeonholeRoutes,
  annotateRoutes,
  bundleRoutes,
  moduleRoutes,
  trainRoutes,
  testRoutes,
  testResultRoutes,
  topPageRoutes,
  userSettingRoutes,
  operationRoutes,
  addWorkRoutes,
  editWorkRoutes
  // dashboardsRoutes,
  // pagesRoutes,
  // profileRoutes,
  // projectsRoutes,
  // orderRoutes,
  // invoiceRoutes,
  // tasksRoutes,
  // calendarRoutes,
  // componentsRoutes,
  // chartRoutes,
  // formsRoutes,
  // tablesRoutes,
  // iconsRoutes,
  // mapsRoutes,
  // presentationRoutes,
  // documentationRoutes,
  // changelogRoutes,
  // privateRoutes
];

export const auth = [authRoutes];

export default [
  // /testAPIRoutes,
  photosRoutes,
  pigeonholeRoutes,
  annotateRoutes,
  bundleRoutes,
  trainRoutes,
  moduleRoutes,
  testRoutes,
  operationRoutes
  // userSettingRoutes
  // dashboardsRoutes,
  // pagesRoutes,
  // profileRoutes,
  // projectsRoutes,
  // orderRoutes,
  // invoiceRoutes,
  // tasksRoutes,
  // calendarRoutes,
  // authRoutes,
  // componentsRoutes,
  // chartRoutes,
  // formsRoutes,
  // tablesRoutes,
  // iconsRoutes,
  // mapsRoutes,
  // presentationRoutes,
  // documentationRoutes,
  // changelogRoutes
];
